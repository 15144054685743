<template>
  <div class="px-4 py-10" v-if="company">
    <section>
      <div
        class="flex flex-col md:flex-row items-start justify-between md:pb-20"
      >
        <div class="flex items-center w-full md:w-8/12">
          <img
            v-if="company.logo"
            :src="company.logo"
            :alt="company.name"
            class="w-24 rounded-full border border-gray-100 p-2"
          />

          <div
            v-else
            class="
              w-24
              h-24
              rounded-full
              border border-gray-100
              flex flex-col
              items-center
              justify-center
              bg-gray-100
              hover:bg-gray-300
            "
          >
            <p
              class="text-gray-500 font-extrabold"
              style="font-family: 'Gluten', cursive"
            >
              putz
            </p>
          </div>
          <div class="flex flex-col mx-4">
            <h1
              class="text-base md:text-4xl text-gray-700 font-bold line-clamp-1"
            >
              {{ company.name }}
            </h1>
            <div class="" v-if="company.evaluation">
              <StarRating
                :increment="0.5"
                :star-size="16"
                :read-only="true"
                :round-start-rating="false"
                :rating="company.evaluation"
                :fixed-points="1"
                text-class=" text-sm text-gray-700 mt-1"
              />
            </div>
            <div class="" v-else>
              <StarRating
                :increment="0.5"
                :star-size="16"
                :read-only="true"
                :round-start-rating="false"
                :rating="company.evaluation"
                text-class=" text-sm text-gray-700 mt-1"
              />
            </div>
          </div>
        </div>
        <div
          class="w-full md:w-4/12 text-center md:text-right py-5 md:p-0 md:mt-1"
        >
          <a href="#!" class="text-sm text-purple-800 font-bold">
            <div
              class="text-gray-600 flex items-center md:justify-end"
              v-if="company.custoMminimumOrder && company.minimumOrderValue"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 inline"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"
                />
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="ml-1 text-sm text-gray-800 font-medium"
                >Pedido mínimo R$
                {{ company.minimumOrderValue | formatprice }}</span
              >
            </div>
          </a>
          <div class="space-y-2 mt-2">
            <p class="flex items-center md:justify-end" v-if="company.telephone">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                />
              </svg>
              <span class="ml-2 text-sm text-gray-800 font-medium">
                {{ company.telephone }}</span
              >
            </p>
            <p class="flex items-center md:justify-end">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                />
              </svg>
              <span class="ml-1 text-sm text-gray-800 font-medium">
                {{ company.cell_phone }}</span
              >
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="my-5">
      <h3 class="font-bold">Sobre:</h3>
      <p class="text-gray-700 text-sm">{{ company.about }}</p>
    </section>

    <section class="my-5 space-y-3">
      <div v-for="(openingHours, index) in openingHoursList" :key="index">
        <span v-if="openingHours[0]">{{ openingHours[0].description }}</span>
        <div
          v-for="(hours, index) in openingHours"
          :key="index"
          class="item__hours"
        >
          <span class="ml-4"> {{ hours.open }} às {{ hours.close }}</span>
        </div>
      </div>
    </section>

    <section class="my-5">
      <h3 class="font-bold my-5">Formas de pagamento aceitas:</h3>

      <div class="flex flex-col" v-if="this.company.customEnableMoney">
          <span class="font-bold text-gray-700">Dinheiro</span>
          
        <div class="border border-gray-200 py-1 px-2 w-14 flex-wrap flex-col-reverse">
      
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-green-400 "
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
          />
        </svg>
        </div>
      </div>

      <div v-for="(payment, index) in paymentMethods" :key="index" class="">
        <span class="my-2 block font-bold text-gray-700" v-if="payment[0]">
          {{ payment[0].type | formatType }}</span
        >
        <div class="flex  md:flex-row space-y-3 md:space-y-0 items-baseline flex-wrap ">
          <div
            v-for="(item, index) in payment"
            :key="index"
            class="flex mr-2 md:flex-row-reverse  items-baseline border border-gray-200 py-1 px-2 w-12/12"
          >
            <span> {{ item.description }} </span>
            <img
              :src="item.image"
              :alt="item.description"
              class="w-5  mx-2"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="mt-28 py-4">
      <p class="text-gray-500">
        O PutzFome é gratuito para os usuários e todos os preços apresentados no
        cardápio são definidos pela própria loja.
      </p>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import StarRating from "vue-star-rating";
export default {
  props: ["companyUrl", "uuid"],
  components: {
    StarRating,
  },

  data() {
    return {
      company: null,
    };
  },

  async created() {
    await this.getCompanySelected(this.companyUrl).then((response) => {
      this.company = response.data;
    });
  },

  methods: {
    ...mapActions(["getCompanySelected"]),
  },
  computed: {
    openingHoursList() {
      const hours = this.company.openingHours.reduce(
        (acc, item) => {
          if (item.description == "Segunda") {
            acc["Segunda"].push(item);
          }
          if (item.description == "Terça") {
            acc["Terça"].push(item);
          }
          if (item.description == "Quarta") {
            acc["Quarta"].push(item);
          }
          if (item.description == "Quinta") {
            acc["Quinta"].push(item);
          }
          if (item.description == "Sexta") {
            acc["Sexta"].push(item);
          }
          if (item.description == "Sabado") {
            acc["Sabado"].push(item);
          }
          if (item.description == "Domingo") {
            acc["Domingo"].push(item);
          }

          return acc;
        },
        {
          Segunda: [],
          Terça: [],
          Quarta: [],
          Quinta: [],
          Sexta: [],
          Sabado: [],
          Domingo: [],
        }
      );
      return hours;
    },
    paymentMethods() {
      const payments = this.company.paymentMethods.reduce(
        (acc, item) => {
          if (item.type == "pix") {
            acc["Pix"].push(item);
          }
          if (item.type == "debit") {
            acc["Debito"].push(item);
          }
          if (item.type == "credit") {
            acc["Credito"].push(item);
          }
          if (item.type == "voucher") {
            acc["Voucher"].push(item);
          }

          return acc;
        },
        {
          Pix: [],
          Debito: [],
          Credito: [],
          Voucher: [],
        }
      );
      return payments;
    },
  },
  filters: {
    formatType(type) {
      if (type === "pix") {
        return (type = "Pix");
      }
      if (type === "credit") {
        return (type = "Crédito");
      }
      if (type === "debit") {
        return (type = "Débito");
      }
      if (type === "voucher") {
        return (type = "Voucher");
      }
    },
  },
};
</script>
